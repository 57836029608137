<template>
  <div id="printPage">
    <iframe
      src="../../views/supplierOrder/SupplierOrderDetails.vue"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
